<template>
  <div class="carousel-wrapper">
    <el-carousel height="100vh" direction="vertical" :interval="5000">
      <el-carousel-item
        v-for="banner in bannerList"
        :key="banner.id"
        class="carousel-item"
      >
        <el-image
          :src="banner.image_url"
          fit="cover"
          class="carousel-item__image"
          @click="gotoLink(banner.link)"
        >
        </el-image>

        <div class="carousel-item__text">
          <div class="title" v-html="banner.title" />

          <div class="subtitle" v-html="banner.subtitle" />

          <router-link :to="{ name: 'Contact Us' }" class="contact">
            <span> Contact Us</span>
            <div class="contact__icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                class="bi bi-chevron-right"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z"
                />
              </svg>
            </div>
          </router-link>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { labels } from "@/common";

  export default {
    name: "Carousel",

    data() {
      return {
        labels,
      };
    },

    computed: {
      ...mapState({ banners: (state) => state.banner.banners }),

      bannerList() {
        if (this.banners) return this.banners.data;
        return [];
      },
    },

    mounted() {
      this.loadBanners();
    },

    methods: {
      loadBanners() {
        this.$store.dispatch("banner/getAllBanners", {
          limit: 20,
          published: 1,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main";

  .carousel-wrapper {
    width: 100%;
  }

  .carousel-item {
    display: flex;
    width: 100%;
    height: 100%;

    &__image {
      width: 50%;
      height: 100%;
      flex-shrink: 0;
    }

    &__text {
      flex-grow: 1;
      padding: 0 3rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .title {
        display: block;
        font-size: clamp(1rem, 8.333vw, 3.125rem);
        font-weight: 400;
        color: $primary-color;
        letter-spacing: -2px;
      }

      .subtitle {
        font-size: 16px;
        color: #00544a;
        line-height: 30px;
        display: block;
        margin-bottom: 0;
      }

      .contact {
        margin-top: 54px;
        font-size: 16px;
        font-weight: 700;
        color: #00544a;
        text-transform: uppercase;
        cursor: pointer;
        display: inline-flex;
        align-items: center;

        &__icon {
          display: grid;
          place-content: center;
          margin-left: 1rem;

          width: 32px;
          height: 32px;
          background: $secondary-color;
          color: #fff;

          border-radius: 100px;
          -webkit-border-radius: 100px;
          -moz-border-radius: 100px;
          -ms-border-radius: 100px;
          transition: 0.3s ease-in-out;
          -webkit-transition: 0.3s ease-in-out;
          -moz-transition: 0.3s ease-in-out;
          -o-transition: 0.3s ease-in-out;

          svg {
            padding-left: 0.25rem;
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .carousel-item {
      flex-direction: column;

      &__image {
        width: 100%;
        height: 40%;
      }

      &__text {
        display: block;
        padding: 2rem;
      }
    }
  }
</style>
