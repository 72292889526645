<template>
  <el-form
    v-loading="loading"
    class="feedback"
    ref="feedbackForm"
    :model="feedback"
    :rules="rules"
    label-position="top"
  >
    <el-row :gutter="20">
      <el-col :span="24" :md="12">
        <el-form-item prop="name" :label="labels.LBL_FULL_NAME">
          <el-input v-model="feedback.name"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="24" :md="12">
        <el-form-item prop="email" :label="labels.LBL_EMAIL">
          <el-input v-model="feedback.email" type="email"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="24" :md="12">
        <el-form-item :label="labels.LBL_CONTACT_NO">
          <el-input v-model="feedback.phone" type="tel"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="24" :md="12">
        <el-form-item :label="labels.LBL_ADDRESS">
          <el-input v-model="feedback.address"></el-input>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item prop="interested_in" label="Intrested In">
          <el-select v-model="feedback.interested_in">
            <el-option
              v-for="item in interests"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>

      <el-col :span="24">
        <el-form-item prop="message" :label="labels.LBL_MESSAGE">
          <el-input v-model="feedback.message" type="textarea" :rows="3">
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item>
      <el-button
        type="primary"
        @click="submitForm('feedbackForm')"
        class="btn-lg"
      >
        Submit
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
  import { labels, rules } from "@/common";
  import Feedback from "@/models/feedback";
  import { mapGetters, mapState } from "vuex";

  export default {
    name: "FeedbackForm",

    data() {
      return {
        labels,
        rules,
        feedback: new Feedback(),
        interests: [
          "Solar for Homes",
          "Solar for Busineess",
          "Battery Storage & more",
          "Others",
        ],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
      }),

      ...mapGetters({ services: "expertise/servicesDDL" }),
    },

    watch: {
      services: {
        handler(nv) {
          this.interests = [...nv];
        },
      },
    },

    created() {
      this.loadServices();
    },

    methods: {
      loadServices() {
        this.$store.dispatch("expertise/getAllServices");
      },

      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store
              .dispatch("feedback/createFeedback", this.feedback)
              .then(() => {
                this.$refs[formName].resetFields();
              });
          }
        });
      },
    },
  };
</script>

<style></style>
