<template>
  <div>
    {{ animatedNumber }}
  </div>
</template>

<script>
  import gsap from "gsap";

  export default {
    name: "AnimatedCounter",

    props: {
      num: { type: Number },
    },

    data() {
      return {
        number: 0,
        tweenedNumber: 0,
      };
    },

    computed: {
      animatedNumber: function() {
        return this.tweenedNumber.toFixed(0);
      },
    },

    watch: {
      number: function(newValue) {
        gsap.to(this.$data, { duration: 2, tweenedNumber: newValue });
      },
    },

    created() {
      this.number = this.num;
    },
  };
</script>

<style></style>
