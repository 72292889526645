<template>
  <div class="info">
    <div class="container default-padding">
      <el-row :gutter="20">
        <el-col :span="24" :lg="12">
          <el-row :gutter="20">
            <el-col :span="24" :lg="11">
              <div class="info__card">
                <icon-base size="65">
                  <icon-battery />
                </icon-base>

                <div class="info__card__count">
                  <animated-counter
                    v-if="projectDelievered"
                    :num="projectDelievered"
                  />
                  <span v-if="projectDelievered > 0" class="ml-1">+</span>
                </div>

                <div class="info__card__title">
                  {{ labels.LBL_PROJECT_DELIVERED }}
                </div>
              </div>
            </el-col>

            <el-col :span="24" :lg="11">
              <div class="info__card">
                <icon-base size="65">
                  <icon-happy />
                </icon-base>

                <div class="info__card__count">
                  <animated-counter
                    v-if="happyClients"
                    :num="happyClients"
                    class="mr-1"
                  />
                  <span v-if="happyClients > 0" class="ml-1">+</span>
                </div>

                <div class="info__card__title">
                  {{ labels.LBL_CLIENTS }}
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>

        <el-col :span="24" :lg="12">
          <el-row>
            <el-col :span="24" :lg="10" class="mb-2">
              <div>
                <div class="item-title">
                  <icon-base size="20" class="mr-1">
                    <icon-phone />
                  </icon-base>
                  {{ labels.LBL_CALL_US }}
                </div>

                <div class="description">
                  <!-- <icon-base class="mr-1">
                    <icon-phone />
                  </icon-base> -->

                  <a :href="`tel:${phoneNo}`">
                    <span>
                      {{ phoneNo }}
                    </span>
                  </a>
                </div>

                <div class="description">
                  <!-- <icon-base class="mr-1">
                    <icon-phone />
                  </icon-base> -->

                  <a :href="`tel:${phoneNo}`">
                    <span>
                      +1 (416) 616-2685
                    </span>
                  </a>
                </div>

                <div class="description ">
                  <!-- <icon-base class="mr-1">
                    <icon-phone />
                  </icon-base> -->

                  <a :href="`tel:${phoneNo}`">
                    <span>
                      +1 (868) 340-3081
                    </span>
                  </a>
                </div>
              </div>
            </el-col>

            <el-col :span="24" :lg="14" class="mb-2">
              <div>
                <div class="item-title">
                  <icon-base size="20" class="mr-1">
                    <icon-mail />
                  </icon-base>
                  {{ labels.LBL_EMAIL_US }}
                </div>

                <div class="description">
                  <!-- <icon-base class="mr-2">
                    <icon-mail />
                  </icon-base> -->

                  <a :href="`mailto:${email}`">
                    <span>
                      {{ email }}
                    </span>
                  </a>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  import { labels } from "@/common";
  import IconBase from "@/components/IconBase.vue";
  import IconPhone from "@/components/icons/IconPhone.vue";
  import IconMail from "@/components/icons/IconMail.vue";
  //import IconLocation from "@/components/icons/IconLocation.vue";
  import IconHappy from "@/components/icons/IconHappy.vue";
  import IconBattery from "@/components/icons/IconBattery.vue";
  import AnimatedCounter from "./AnimatedCounter.vue";

  export default {
    components: {
      IconBase,
      IconPhone,
      IconMail,
      //IconLocation,
      IconHappy,
      IconBattery,
      AnimatedCounter,
    },

    data() {
      return { labels, company: null };
    },

    computed: {
      phoneNo() {
        return this.company && this.company.phone;
      },

      email() {
        return this.company && this.company.email;
      },

      counts() {
        return this.company && this.company.count;
      },

      projectDelievered() {
        const placeholder = 4;
        if (!this.counts) return;
        return this.counts.project_delievered > 0
          ? this.counts.project_delievered
          : placeholder;
      },

      happyClients() {
        const placeholder = 12;
        if (!this.counts) return;
        return this.counts.happy_clients > 0
          ? this.counts.happy_clients
          : placeholder;
      },
    },

    created() {
      this.loadOrgDetails();
    },

    methods: {
      async loadOrgDetails() {
        this.company = await this.$store.dispatch(
          "organization/getOrganizationDetail"
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .info {
    width: 100%;
    z-index: 10;
    &__card {
      padding: 1.875rem 0 0.625rem;

      margin-top: -110px;
      border-radius: 0.25rem;
      background: $primary-color;
      color: white;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__title {
        font-size: 1rem;
        font-weight: 200;
        margin-bottom: 15px;
        line-height: 24px;
      }

      &__count {
        font-size: 3.5rem;
        font-weight: 600;
        line-height: 60px;
        margin: 15px 0;
        letter-spacing: -2px;
        display: flex;
      }
    }
  }

  .item-title {
    font-size: 1.375rem;
    font-weight: 700;
    margin-bottom: 15px;
  }

  .description {
    font-size: 1rem;
    line-height: 1.9375rem;
    display: flex;
    align-items: center;

    svg {
      flex-shrink: 0;
    }
  }

  @media only screen and (max-device-width: 768px) {
    .info__card {
      margin-top: 0;
      margin-bottom: 2rem;
    }
  }
</style>
