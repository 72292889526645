<template>
  <div class="wrapper">
    <banner-carousel />

    <quick-info />
    <!-- Service Grid  -->
    <div class="services">
      <div class="container default-padding">
        <div class="primary-heading text-center" style="color: white">
          {{ labels.LBL_SERVICE_MAIN_TITLE }}
        </div>

        <div class="paragraph text-center mb-6" style="color: white">
          {{ labels.PH_SERVICES }}
        </div>
        <services-grid :services="serviceCards" />

        <div class="text-center">
          <router-link to="/services" tag="el-button"> + More</router-link>
        </div>
      </div>
    </div>

    <!-- Tools and technology -->
    <div class="tools-and-tech">
      <div class="container default-padding">
        <tools-and-tech />
      </div>
    </div>

    <!-- Enquiry -->
    <div class="enquiry">
      <div class="enquiry__overlay">
        <div class="container default-padding">
          <el-row>
            <el-col :span="24" :lg="12" class="mb-6">
              <div class="primary-heading mb-4 main-title">
                Request a Quote
              </div>

              <img
                :src="require('@/assets/images/defaults/developer.svg')"
                alt="developer"
                style="width: 100%"
              />
            </el-col>
            <el-col :span="24" :lg="12">
              <div class="enquiry__form">
                <feedback-form />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>

    <!-- Testimonial Slider -->
    <div class="testimonials">
      <div class="container default-padding">
        <testimonials />
      </div>
    </div>
  </div>
</template>

<script>
  import { labels, services } from "@/common";
  import BannerCarousel from "@/components/BannerCarousel.vue";
  import QuickInfo from "@/components/QuickInfo.vue";
  import ServicesGrid from "../components/ServicesGrid.vue";
  import Testimonials from "@/components/ClientTestimonials";
  import FeedbackForm from "@/components/FeedbackForm.vue";
  import ToolsAndTech from "@/components/ToolsAndTech.vue";

  export default {
    name: "Home",

    components: {
      BannerCarousel,
      QuickInfo,
      ServicesGrid,
      Testimonials,
      FeedbackForm,
      ToolsAndTech,
    },

    data() {
      return {
        labels,
        serviceCards: [...services].slice(0, 3),
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .wrapper {
    width: 100%;
  }
  .services,
  .tools-and-tech,
  .enquiry,
  .testimonials {
    padding: 3rem 0;
    width: 100%;
  }

  .services,
  .enquiry {
    background: $background-color;
  }

  .enquiry {
    &__form {
      padding: 1rem;
      border-radius: 0.25rem;
      background: rgba($color: white, $alpha: 0.6);
      backdrop-filter: blur(5px);
    }
  }
</style>
