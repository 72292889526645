<template>
  <el-row>
    <el-col :span="24">
      <div class="primary-heading text-center">
        {{ labels.LBL_TESTIMONIALS }}
      </div>

      <div class="caption text-center mb-6">
        {{ labels.PH_TESTIMONIALS }}
      </div>

      <div v-if="!loading">
        <slider
          v-if="allTestimonials && allTestimonials.length"
          :slides="allTestimonials"
        >
          <template v-slot:default="{ slide }">
            <div class="reviewer">
              <div class="reviewer__image">
                <img :src="slide.image_url" alt="Reviewer" />
              </div>

              <div class="reviewer__detail">
                <div class="name">{{ slide.client_name }}</div>

                <p class="disabled">{{ slide.client_business }}</p>

                <div class="review paragraph" v-html="slide.testimonial" />
              </div>
            </div>
          </template>
        </slider>

        <div v-else class="text-center">{{ labels.PH_TESTIMONIAL_NF }}</div>
      </div>

      <div v-else>{{ `${labels.LBL_LOADING}...` }}</div>
    </el-col>
  </el-row>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";
  import Slider from "@/components/DynamicSlider.vue";

  export default {
    name: "Testimonials",

    components: { Slider },

    data() {
      return {
        labels,
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        testimonials: (state) => state.testimonial.testimonials,
      }),

      allTestimonials() {
        return this.testimonials && this.testimonials.data;
      },
    },

    created() {
      this.loadTestimonials();
    },

    methods: {
      loadTestimonials() {
        this.$store.dispatch("testimonial/getAllTestimonials");
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .reviewer {
    display: flex;
    align-items: flex-start;
    width: 700px;

    &__image {
      flex-shrink: 0;
      height: 200px;
      width: 200px;
      margin-right: 2rem;
      border-radius: 50%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &__detail {
      padding: 1rem;

      .name {
        margin-bottom: 0.5rem;
        font-size: 1.5rem;
        line-height: 1.725rem;
        font-weight: 600;
      }

      .review {
        // @include line-clamp(8);
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .reviewer {
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;

      &__image {
        margin: 0;
        margin-bottom: 1rem;
      }

      &__detail {
        text-align: center;
      }
    }
  }
</style>
